<template>
  <div>
     <!-- <ul>
      <li>
        <router-link to="/">home</router-link>
      </li>

        <li>
        <router-link :to="{name:'xx'}">wpsweb</router-link>
      </li>

      <li>
        <router-link to="/login">login</router-link>
      </li>
      <button @click="logout">logout</button>
    </ul> -->
    <button @click="open">open test</button>
     {{ JSON.stringify(this.$store.state.auth.user)}}
  </div>
</template>

<script>
export default {
  name: 'Sample',
  methods :{
    // 跳转 使用sessionStorage，避免关键信息在ip中暴露
    // 使用push会停留当前页面，故不采纳
    // params 传递参数，子组件无法渲染iframe组件，故不采纳
    open: function(){
       sessionStorage.jurl = "https://wwo.wps.cn/office/s/132aa30a87064?_w_appid=b529bee795124bd3bd4e8f212c29d7a4&_w_tokentype=1&_w_signature=FVhc8TevWlxM0oLxShgsbXEr5cU%3D";
       sessionStorage.jtoken ="xxx1";
       const jump = this.$router.resolve({name: 'excelcontainer'});
            window.open(jump.href,'_blank');
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
